define('tm3/pods/matter/subjects/info/route', ['exports', 'tm-common/mixins/analytics-util', 'tm3/mixins/alert-users-route', 'tm3/config/environment'], function (exports, _analyticsUtil, _alertUsersRoute, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_analyticsUtil.default, _alertUsersRoute.default, {
        notify: Ember.inject.service(),
        metrics: Ember.inject.service(),

        // currrent-matter service for use in dashboard and related routes
        currentMatter: Ember.inject.service(),
        matterId: null,
        analyticList: Ember.inject.service(),
        analyticsFormsData: Ember.inject.service(),
        lists: Ember.inject.service(),
        counts: Ember.inject.service(),

        // param used to reload the data after transition
        queryParams: {
            refresh: {
                refreshModel: true
            }
        },

        subjectChanged: false,
        oldSubjectId: null,

        beforeModel: function beforeModel(transition) {
            this.set('matterId', transition.params.matter.matter_id);
        },


        /**
         * load the current subject
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            var settingsRecords = this.store.peekAll('user-setting');

            return Ember.RSVP.hash({
                subject: this.store.query('subject', {
                    resource_id: params.subject_id,
                    with: 'subject_addrs,documents,notes,hyperlinks,timeline_events,subject_revisions,analytics'
                }),

                // timeline
                events: this.store.query('timeline-event', {
                    matter_id: this.get('currentMatter.id')
                }),
                eventTypes: this.store.findAll('timeline-event-type', { backgroundReload: false }),
                timelineEventParents: this.store.findAll('timeline-event-parent', { backgroundReload: false }),
                consolidatedTags: this.store.query('db-tag', {
                    parent_id: [_environment.default.APP.tags.stateCategory, _environment.default.APP.tags.countryCategory, 'NULL']
                }),

                // end timeline
                matterWorkflows: this.store.query('matter-has-workflow', {
                    matter_id: this.get('matterId'),
                    sort: 'weight'
                }),
                posts: this.store.query('post', {
                    with: 'hyperlinks',
                    'hyperlinks:subject_id': params.subject_id
                }),
                dbMemos: this.get('counts').query('db-memo', {
                    matter_id: params.matter_id,
                    with: 'v_resource_has_resources,dbs',
                    'v_resource_has_resources:child_id': params.subject_id
                }),
                workflows: this.store.query('subject-workflow-v2', {
                    subject_id: params.subject_id
                }),
                phrases: this.store.query('field-phrase', {
                    matter_id: ['NULL', this.get('matterId')]
                }),
                initialSubjects: this.get('store').query('subject', {
                    matter_id: this.get('currentMatter.id'),
                    limit: 100,
                    sortField: 'last_name'
                }),
                userSettings: Ember.isEmpty(settingsRecords) ? this.store.query('user-setting', {
                    group_name: 'user_settings'
                }) : settingsRecords,
                documentTypes: this.get('lists').getListForGroup('jury_doc_type_tm'),
                stateAbbreviations: this.get('lists').getListForGroup('states_abbreviations'),
                analyticsLists: this.get('analyticList').fetchMultipleLists(['states', 'property', 'civil_analysis', 'criminal_analysis', 'criminal_charges', 'criminal_rights', 'traffic_analysis', 'employment_status', 'occupations', 'employment_analysis', 'employment_analysis_category', 'education_analysis', 'socnet_other', 'socnet_themes', 'socnet_themes_hidden', 'socnet_tones', 'possible_connections_category', 'marital_status', 'has_children', 'children', 'employment_type', 'employment_length', 'product_use', 'product_use_tobacco', 'addiction_withdrawal_experience', 'juror_medical_issues', 'political_views', 'media_outlet', 'higher_education', 'higher_education_masters', 'higher_education_post_grad', 'religious_interests', 'religious_affiliation', 'religious_level_of_interest']),
                alertUsersContacts: this.contactsModelMixin(),
                alertUsersEmployees: this.employeesModelMixin()
            });
        },


        /**
         * pre model
         * verify that a subject was loaded correctly
         * @param controller
         * @param model
         */
        setupController: function setupController(controller, model) {
            var subject = model.subject.get('firstObject');

            // timeline processing
            controller.set('states', model.consolidatedTags.filterBy('parentId', _environment.default.APP.tags.stateCategory));
            controller.set('countries', model.consolidatedTags.filterBy('parentId', _environment.default.APP.tags.countryCategory));
            controller.set('showNewEventForm', false);
            controller.set('selectedCategory', null);

            // end timeline processing

            if (typeof subject === 'undefined') {
                this.transitionTo('matter.subjects.list');
            }
            this.set('subjectChanged', Ember.isEmpty(this.get('oldSubjectId')) ? false : this.get('oldSubjectId') !== subject.get('id'));
            this.set('oldSubjectId', subject.get('id'));
            var currentMatter = this.get('currentMatter');

            // remove some hard coded values from the list
            controller.set('matterFields', currentMatter.get('matterFields').filter(function (mf) {
                return mf.get('isViewableAdminSubjectProfile');
            }));
            controller.set('matterWorkflows', model.matterWorkflows);
            controller.set('dbMemos', model.dbMemos);
            controller.set('posts', model.posts);

            this.get('analyticsFormsData').buildData(subject, model.analyticsLists);

            var fieldLabels = currentMatter.get('matterFields').map(function (mf) {
                return mf.get('display');
            });

            controller.set('phrases', model.phrases.filter(function (phrase) {
                return fieldLabels.includes(phrase.get('field'));
            }));
            controller.set('initialSubjects', model.initialSubjects);
            controller.set('switchSubject', false);
            controller.set('hideRight', false);
            controller.set('hideLeft', false);

            var refreshTimeSetting = model.userSettings.findBy('name', 'subject_refresh_time');
            var autosaveSetting = model.userSettings.findBy('name', 'subjects_autosave');

            controller.set('refreshTimeSeconds', refreshTimeSetting ? refreshTimeSetting.get('value') : 300);
            controller.set('isAutoSaving', autosaveSetting ? autosaveSetting.get('value') === 'true' : false);
            controller.set('events', model.events);
            controller.set('eventTypes', model.eventTypes.sortBy('name'));
            controller.set('timelineEventParents', model.timelineEventParents);
            controller.set('documentTypes', model.documentTypes);
            controller.set('stateAbbreviations', model.stateAbbreviations);
            controller.set('alertUsersContacts', model.alertUsersContacts);
            controller.set('alertUsersEmployees', model.alertUsersEmployees);

            controller.set('assignedWorkflows', model.workflows.rejectBy('assignedTo', null).length);

            this._super(controller, subject);
        },
        resetIsEditingFlags: function resetIsEditingFlags() {
            // eslint-disable-next-line ember/no-controller-access-in-routes
            this.controller.set('isEditing', false);
            // eslint-disable-next-line ember/no-controller-access-in-routes
            this.controller.set('isHistory', false);
            // eslint-disable-next-line ember/no-controller-access-in-routes
            this.controller.set('showEditInPlace', false);
            // eslint-disable-next-line ember/no-controller-access-in-routes
            this.controller.set('isEditingSingleField', false);
        },


        actions: {
            willTransition: function willTransition(transition) {
                var target = transition.targetName;

                var isEditing = this.get('controller.isEditing') || this.get('controller.showEditInPlace') || this.get('controller.isEditingSingleField');

                if (isEditing && target.indexOf('matter.subjects.info') < 0 &&
                // eslint-disable-next-line no-alert, no-restricted-globals
                !confirm('Are you sure you want to navigate away from the edit form? Any unsaved edits will be lost.')) {
                    transition.abort();

                    return true;
                }

                if (target.indexOf('matter.subjects.info') < 0) {
                    this.resetIsEditingFlags();
                }
                this.set('controller.showNewEventForm', false);
                this.set('controller.showEditEventForm', false);
                var events = this.store.peekAll('timeline-event');

                events.forEach(function (event) {
                    event.rollbackAttributes();
                });

                return false;
            },
            didTransition: function didTransition() {
                if (this.get('subjectChanged')) {
                    this.resetIsEditingFlags();
                    this.set('subjectChanged', false);
                }
            },
            fetchSubject: function fetchSubject() {
                this.refresh();
            },
            refreshModel: function refreshModel() {
                this.refresh();
            },


            /**
             * publish the subject's current version to the client portal
             *
             * @param model
             */
            publishSubject: function publishSubject(model) {
                var _this = this;

                model.publish().then(function () {
                    // do something when the API returns a response
                    _this.get('notify').success('Subject information published');

                    Ember.get(_this, 'metrics').trackEvent({
                        category: 'Subjects',
                        action: 'publish',
                        label: model.get('id')
                    });
                    _this.refresh();

                    /* const self = this;
                       this.store.query('subject-revision', { resource_id: self.get('model.id') }).then(() => {
                        this.get('notify').success('Refreshed History Information');
                       }); */
                }).catch(function () {
                    _this.get('notify').error('There was a problem trying to publish this subject.');
                });
            },
            publishLimitedSubject: function publishLimitedSubject(model) {
                var _this2 = this;

                model.publishLimited().then(function () {
                    // do something when the API returns a response
                    _this2.get('notify').success('Limited Subject information published');

                    Ember.get(_this2, 'metrics').trackEvent({
                        category: 'Subjects',
                        action: 'publish-limited',
                        label: model.get('id')
                    });
                    _this2.refresh();

                    /* const self = this;
                       this.store.query('subject-revision', { resource_id: self.get('model.id') }).then(() => {
                        this.get('notify').success('Refreshed History Information');
                       }); */
                }).catch(function () {
                    _this2.get('notify').error('There was a problem trying to publish-limit this subject.');
                });
            },


            /**
             * reset the subject's current version to the client portal
             *
             * @param model
             */
            resetSubject: function resetSubject(model) {
                var _this3 = this;

                model.reset().then(function () {
                    // do something when the API returns a response
                    _this3.get('notify').success('Subject information published');

                    Ember.get(_this3, 'metrics').trackEvent({
                        category: 'Subjects',
                        action: 'reset',
                        label: model.get('id')
                    });
                    _this3.refresh();

                    /* const self = this;
                       this.store.query('subject-revision', { resource_id: self.get('model.id') }).then(() => {
                        this.get('notify').success('Refreshed History Information');
                       }); */
                }).catch(function () {
                    _this3.get('notify').error('There was a problem trying to publish this subject.');
                });
            },
            toggleFormIsOpen: function toggleFormIsOpen() {
                this.toggleProperty('controller.formIsOpen');
            },
            modifyFormIsOpen: function modifyFormIsOpen(value) {
                this.set('controller.formIsOpen', value);
            }
        }
    });
});