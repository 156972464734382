define('tm3/pods/components/top-nav/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var mobileWidthThreshold = 763;

    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),

        classNames: ['main-header', 'top-nav'],
        showHamburgerMenu: true,
        tagName: 'header',

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var $body = Ember.$('body');

            Ember.$('.content-wrapper').on('click.push_menu', function () {
                // Enable hide menu when clicking on the content-wrapper on small screens
                if (Ember.$(window).width() <= mobileWidthThreshold && $body.hasClass('sidebar-open')) {
                    $body.removeClass('sidebar-open');
                }
            });
        },


        isSubContractor: Ember.computed('session.data.authenticated.groups', function () {
            return this.get('session.data.authenticated.groups').findBy('name', 'Sub-Contractor');
        }),

        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            Ember.$('.content-wrapper').off('click.push_menu');
        },


        actions: {
            /**
                Handles sidebar display state.
                 - Mobile (defined here as <= 763px) is either completely hidden or completely open
                - Desktop is either completely open or icons only (never completely closed)
                 Note: When neither class name is applied, the default for mobile is
                collapsed (hidden completely) and the default for desktop is
                fully open.
                 @method toggleMenu
             */
            toggleMenu: function toggleMenu() {
                var $body = Ember.$('body');
                var viewportWidth = window.innerWidth;
                var isMobile = viewportWidth <= mobileWidthThreshold;

                if (isMobile) {
                    // If mobile view and sidebar is open...
                    if ($body.hasClass('sidebar-open')) {
                        // ...then remove all classes that might cause the sidebar to appear.
                        $body.removeClass('sidebar-collapse sidebar-open').trigger('collapsed.pushMenu');
                    } else {
                        $body.addClass('sidebar-open').trigger('expanded.pushMenu');
                    }
                } else if ($body.hasClass('sidebar-collapse')) {
                    /*
                        Otherwise, if the viewport is desktop-size and the sidebar
                        is collapsed (icons only) then fully expand it, otherwise...
                     */
                    $body.removeClass('sidebar-collapse').addClass('sidebar-open').trigger('expanded.pushMenu');
                } else {
                    // ...collapse it.
                    $body.removeClass('sidebar-open').addClass('sidebar-collapse').trigger('collapsed.pushMenu');
                }
            }
        }
    });
});