define('tm3/pods/components/case-management/custom-cell/component', ['exports', 'ember-light-table/components/cells/base', 'tm-common/mixins/crud/error', 'tm3/pods/components/case-management/custom-cell/template'], function (exports, _base, _error, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_error.default, {
        layout: _template.default,
        notify: Ember.inject.service(),

        actions: {
            editField: function editField() {
                this.set('row.isCollapsed', false);
            },
            saveField: function saveField(value) {
                var _this = this;

                var matter = this.get('row.content');
                matter.save().then(function () {
                    _this.get('notify').success('Saved successfully');
                    _this.get('tableActions.refresh')();
                }).catch(function () {
                    _this.get('tableActions.refresh')();
                    _this.validationReport(matter);
                });
            },
            rollbackField: function rollbackField() {
                this.get('row.content').rollbackAttributes();
            }
        }
    });
});