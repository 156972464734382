define('tm3/validators/budget', ['exports', 'ember-changeset-validations/validators', 'tm3/validators/start-date', 'tm3/validators/due-date'], function (exports, _validators, _startDate, _dueDate) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: [(0, _validators.validatePresence)({ presence: true, message: 'Name is required' })],
        trackedType: [(0, _validators.validatePresence)({ presence: true }), (0, _validators.validateInclusion)({ list: ['dollars', 'hours', 'quantity'], message: 'Type should be one of the options' })],
        includeExpenses: [(0, _validators.validatePresence)(true)],
        amount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0 })],
        estimatedHours: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],
        notes: (0, _validators.validateLength)({ min: 3, allowBlank: true }),
        startOn: [(0, _startDate.default)()],
        dueOn: [(0, _dueDate.default)()]
    };
});