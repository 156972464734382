define('tm3/pods/components/matter/matter-connection/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['link label matter-connection'],
        classNameBindings: ['isPrimary:label-success:label-default'],
        isPrimary: Ember.computed.alias('connection.primary'),
        showX: false,
        connection: null,
        mouseEnter: function mouseEnter() {
            this.set('showX', true);
        },
        mouseLeave: function mouseLeave() {
            this.set('showX', false);
        },

        actions: {
            deleteConnection: function deleteConnection() {
                this.sendAction('deleteConnection');
            },
            editConnection: function editConnection() {
                this.sendAction('editConnection');
            }
        }
    });
});